import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import { TileSection, Tile } from '../../components/tile-section';
import Discover from '../../components/discover';
import { Languages } from '../../tools/languages';
import PlaceholderImage from '../../../static/images/placeholder.jpg';

import { graphql } from 'gatsby';

import './index.scss';

const NewsSection = ({ newsItems }) => {
  const { items } = newsItems;

  return (
    <TileSection className="news-tile-section" threeColumns>
      {items.map((newsItem) => {
        const { id, slug, published, title, image } = newsItem.node;
        return (
          <Tile
            key={id}
            img={image && image.childImageSharp ? image.childImageSharp.fluid : PlaceholderImage}
            title={published}
            name={title}
            to={slug}
          />
        );
      })}
    </TileSection>
  );
};

const DutchNewsPage = ({ location, data, language = Languages.NL }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title="Nieuws" lang={language} />
      <Navigation />
      <main>
        <h1>Nieuws</h1>
        <NewsSection newsItems={data.newsItems} />
        <Discover language={language} dark={true} />
      </main>
    </>
  );
};

export const query = graphql`
  {
    newsItems: allStrapiNewsItems(sort: { fields: [published], order: [DESC] }) {
      items: edges {
        node {
          id
          slug
          title: title_nl
          published(formatString: "D MMMM YYYY", locale: "NL")
          image {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default DutchNewsPage;
